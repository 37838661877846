


import imgOne from '../../assets/image0.jpeg'
import img2 from '../../assets/image1.jpeg'
import img3 from '../../assets/image1.png'
import img4 from '../../assets/image2.jpeg'
import img5 from '../../assets/image3.jpeg'
import img6 from '../../assets/image4.jpeg'

export const arrImg = [imgOne, img2, img3, img4, img5, img6]

